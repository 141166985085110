import React, {useEffect, useMemo, useState} from 'react';
import FileProvider from '../../followUp/Components/helpers/file.provider';
import {
  checkFileMIMEType,
  checkFileSize,
  getFormattedSize,
} from '../../followUp/Components/helpers/files.helper';
import Swal from 'sweetalert2';
import {Button} from '../../../components/Component';
import Content from '../../../layout/content/Content';
import axios from 'axios';
import {useAuth0} from '@auth0/auth0-react';
import uploadFiles from '../../followUp/Components/helpers/add_file';
import {nanoid} from 'nanoid';
import excelIntegration from './send-data';
import * as XLSX from 'xlsx';

const blobToBase64 = async (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const SellerFilesTest = ({
  idcliente,
  skus,
  date,
  setReload,
  reload,
  setData,
  data,
  atribute,
}) => {
  // const { user } = useAuth0()

  const MAXIMUM_FILES_LIMIT = null;
  const MAXIMUM_FILE_SIZE = 20; // Size in MB
  const ALLOWED_FORMATS =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  const [files, setFiles] = useState([]);

  const [submitting, setSubmitting] = useState(false);

  const [roles, setRoles] = useState([]);
  const getCurrentFiles = async (event) => {
    const rawFiles = event.target.files;
    const fileList = []; // WIll contain an array of type File[]
    if (!rawFiles) {
      return;
    }
    if (rawFiles instanceof Array) {
      rawFiles.forEach((file) => fileList.push(file));
    } else {
      const rawFilesArray = Array.from(rawFiles);
      rawFilesArray.forEach((file) => fileList.push(file));
    }
    const finalFileList = [];
    for (const file of fileList) {
      const extensionSeparatorIndex = file.name.lastIndexOf('.');
      const formattedName = file.name.substring(
        0,
        extensionSeparatorIndex
      );
      const extension = file.name.substr(extensionSeparatorIndex);
      const encodedData = await blobToBase64(file);
      const formattedFile = {
        name: formattedName,
        extension,
        file: encodedData?.toString() ?? '',
        type: 'append',
        size: file.size,
      };
      if (
        files.find(
          (appendedFile) => appendedFile.name === formattedFile.name
        )
      ) {
        Swal.fire({
          icon: 'info',
          title: `The file ${
            formattedFile.name + formattedFile.extension
          } is already added`,
        });
        break;
      }
      if (!checkFileMIMEType(file.type, ALLOWED_FORMATS)) {
        Swal.fire({
          icon: 'warning',
          title:
            'Please select another file (Supported formats: images and PDF)',
          html: `<small class="text-muted">The file ${file.name} is not supported</small>`,
        });
        break;
      }
      if (!checkFileSize(file.size, MAXIMUM_FILE_SIZE)) {
        Swal.fire({
          icon: 'warning',
          title: `The maximum file size is ${MAXIMUM_FILE_SIZE}MB`,
          html: `<small class="text-muted">The file ${
            file.name
          } have a size of ${getFormattedSize(file.size, 2)}</small>`,
        });
        break;
      }
      if (MAXIMUM_FILES_LIMIT) {
        if (files.length < MAXIMUM_FILES_LIMIT) {
          finalFileList.push(file);
        } else {
          Swal.fire({
            icon: 'info',
            title: `The maximum file quantity to upload is ${MAXIMUM_FILES_LIMIT}`,
          });
          break;
        }
      } else {
        const file = event.target.files[0];

        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data);
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        const date = [];
        let w = 0;
        for (const i in worksheet) {
          if (
            i.includes('B') &&
            w >= 0 &&
            (atribute.label === 'ATA' ||
              atribute.label === 'ETA' ||
              atribute.label === 'ATD' ||
              atribute.label === 'ETD' ||
              atribute.label === 'Date Booking' ||
              atribute.label === 'Confirmation Booking' ||
              atribute.label === 'Documental Cut Off' ||
              atribute.label === 'Physical Cut Off' ||
              atribute.label === 'Documental Cut Off (IMO)' ||
              atribute.label === 'VGM Cut Off' ||
              atribute.label === 'Permit (Date)' ||
              atribute.label === 'Custom Transmission Date')
          ) {
            date.push(worksheet[i].w);
          }

          if (
            i.includes('B') &&
            (atribute.label === 'ATA' ||
              atribute.label === 'ETA' ||
              atribute.label === 'ATD' ||
              atribute.label === 'ETD' ||
              atribute.label === 'Date Booking' ||
              atribute.label === 'Confirmation Booking' ||
              atribute.label === 'Documental Cut Off' ||
              atribute.label === 'Physical Cut Off' ||
              atribute.label === 'Documental Cut Off (IMO)' ||
              atribute.label === 'VGM Cut Off' ||
              atribute.label === 'Permit (Date)' ||
              atribute.label === 'Custom Transmission Date')
          )
            w += 1;

          if (
            i.includes('C') &&
            worksheet[i].w !== 'DATE' &&
            worksheet[i].w !== 'FECHA' &&
            w === 0
          ) {
            date.push(worksheet[i].w);
          }
        }

        let p = 0;
        const aux = jsonData.map((element) => {
          let row = {};

          row = {
            target: atribute.prop,
            data: {
              po: Object.values(element)[0],
              value: Object.values(element)[1],
              date: undefined,
            },
          };
          if (
            atribute.label === 'ATA' ||
            atribute.label === 'ETA' ||
            atribute.label === 'ATD' ||
            atribute.label === 'ETD' ||
            atribute.label === 'Date Booking' ||
            atribute.label === 'Confirmation Booking' ||
            atribute.label === 'Documental Cut Off' ||
            atribute.label === 'Physical Cut Off' ||
            atribute.label === 'Documental Cut Off (IMO)' ||
            atribute.label === 'VGM Cut Off' ||
            atribute.label === 'Permit (Date)' ||
            atribute.label === 'Custom Transmission Date' ||
            atribute.label.substr(0, 8) === 'Document'
          ) {
            const dateO = new Date(date[p]);
            const year = dateO.getFullYear();
            let m = dateO.getMonth() + 1;
            let d = dateO.getDate();

            if (m < 10) m = `0${m}`;
            if (d < 10) d = `0${d}`;

            let formatted = `${d}/${m}/${year}`;

            if (formatted === 'NaN/NaN/NaN') {
              formatted = date[p];
            }

            if (atribute.label.substr(0, 8) === 'Document') {
              row.data.date = formatted;
            } else {
              row.data.value = formatted;
            }
          }
          p += 1;
          return row;
        });
        setData(aux);
      }
    }
    if (finalFileList.length > 0) {
      setFiles([...files, ...finalFileList]);
    }

    event.target.value = event.target.defaultValue; // Reset input cached value
  };

  //
  const handleFilesSubmit = async (event) => {
    event.preventDefault();
    const response = await excelIntegration(data);
    if (response.data.state) {
      await Swal.fire({
        title: `${
          files.length > 1 ? 'Files' : 'File'
        } uploaded successfully`,
        icon: 'success',
      });
      setFiles([]);
    } else {
      Swal.fire({
        title: 'Opps!!',
        text: response.error,
        icon: 'warning',
      });
    }
  };

  // remove file
  const removeFile = (event, index) => {
    if (event.isTrusted) {
      const currentFiles = Object.create(files);
      currentFiles.splice(index, 1);
      setFiles([...currentFiles]);
    }
  };

  return (
    <React.Fragment>
      {/* {!isReadOnly && ( */}
      <form onSubmit={handleFilesSubmit}>
        <div className="input-group">
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input"
              onChange={getCurrentFiles}
              id="file-uploader"
            />
            <label
              className="custom-file-label"
              htmlFor="file-uploader"
            >
              Choose files
            </label>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            {data.length !== 0 && (
              <Button
                size="large"
                variant="outlined"
                color="primary"
                type="submit"
              >
                Upload listed files
              </Button>
            )}
          </div>
        </div>
      </form>
      {/* )} */}
    </React.Fragment>
  );
};
