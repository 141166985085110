/* eslint-disable no-unused-vars */

export const userData = [
  {
    id: 1,
    pickupaddress: {
      pickup: [
        {
          idpick: 0,
          numero: "789",
          calle: "Uxmal",
          barrio: "Santa Cruz Atoyac",
          ciudad: "Ciudad de México",
          estado: "Ciudad de México",
          pais: "México",
          zip: "03310"
        },
        {
          idpick: 1,
          numero: "789",
          calle: "Calle Guadalupe",
          barrio: "Zona Centro",
          ciudad: "Durango",
          estado: "Durango",
          pais: "México",
          zip: "34000"
        }
      ]
    },
    legaladdress: {
      numero: "5678",
      calle: "Calle Carnero",
      barrio: "Arboledas",
      ciudad: "Zapopan",
      estado: "Jalisco",
      pais: "México",
      zip: "45070"
    },
    id_cliente: "amazon|amzn1.account.AHG4S7Q35E4KB5DZGK6BENXDZ6IQ",
    legalname: "SELLER TEST",
    dbaname: "My Seller",
    tipocorporacion: "SOCIEDAD ANONIMA",
    tax_id: "43438989",
    contactname: "Juan Perez J.",
    cargo: "Gerente",
    email: "fguerrab@innovaventures.net",
    telefono: "+56977934344",
    ejecutivoamazon: "Juan de Las Mercedes",
    website: "www.ejemplo.com",
    fecha_creacion: "Tue Jan 11 2022",
    activo: true,
    status: "Active",
    email_amazon: "fguerrab@innovaventures.net",
    cbp_number: "26-23456",
    bond_number: "CPQ35476",
    bond_date: "21-11-2021",
    bond_deadline: "20-11-2022",
    fsvp_program: "Active",
    fsvp_date: "21-11-2021",
    fsvp_deadline: "20-11-2022",
    master_poa: "Amazon Logistics (INLT)",
    poa_date: "21-11-2021",
    poa_deadline: "20-11-2022",
    customs_category: "FIOR"
  },
];

export const notes = [
  {
    id: 1,
    text: "Aproin at metus et dolor tincidunt feugiat eu id quam. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean sollicitudin non nunc vel pharetra.",
    date: "Enero 18, 2022",
    time: "5:34",
    company: "ECL LLC",
  },
  {
    id: 2,
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eleifend libero semper metus aliquam tempus. Sed efficitur elit et ligula lobortis",
    date: "Enero 18, 2022",
    time: "7:00",
    company: "ECL LLC",
  },
];

export const filterStatus = [
  { value: "Active", label: "Active" },
  { value: "Pending", label: "Pending" },
  { value: "Suspend", label: "Suspend" },
];

export const filterRole = [
  { value: "investor", label: "Investor" },
  { value: "seller", label: "Seller" },
  { value: "buyer", label: "Buyer" },
];

export const countryOptions = [
  { value: "Canada", label: "Canada" },
  { value: "USA", label: "USA" },
  { value: "India", label: "India" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "France", label: "France" },
  { value: "England", label: "England" },
];
