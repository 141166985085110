import {BACKEND} from '../../consts/backend';
const GetFollowupExpoNew = async (filter) => {
  var requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  let url = `${BACKEND}/expo/dashboard_info`

  if (filter) {
    switch(filter){
      case '180days':
        url+='?first_date=180'
        break;
      default:
        url=`?first_date=${filter.start}&last_date=${filter.finish}`
    }
  }


  const resp = await fetch(
    url,
    requestOptions
  );

  const sku = await resp.json();

  return sku;
};

export default GetFollowupExpoNew;
