import React, {useState, useEffect} from 'react';
import Content from '../../../layout/content/Content';
import Head from '../../../layout/head/Head';
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
} from '../../../components/Component';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import * as XLSX from 'xlsx';
import {Spinner} from 'reactstrap';
import GetFollowupExpoNew from '../../Reports/expoReportNew';
import {nanoid} from 'nanoid';
import {Follow_Report} from './helpers/followup_report';

const FollowupReport = () => {
  const [data, setData] = useState([]);
  const [dataTest, setDataTest] = useState([]);

  const label = [
    'Sale Order', //
    'PO Number', //
    'SSB Ref.', //
    'Invoice', //
    'Shipper', //
    'Consignee', //
    'Product ID', //
    'Product Description', //
    'Freight BL', //
    'Plant Exit Date - Estimated',
    'Plant Exit Date - Actual',
    'ETD Date',
    'ATD Date',
    'ETA Date',
    'ATA Date',
    'Original ETA - Date',
    'Order Rush',
    'EntryNumber',
    'Customs Transmission Date',
    'Customs Clearance Date',
    'Doc sent - Date',
    //'Note',
    'Carrier',
    'Booking Number',
    'Booking Confirmation Date',
    'Destination',
    'Country of destinations',
    'Modal',
    'Type of Cargo',
    'Netwight KG-Shipment',
    'Register Date',
    'Plant ID',
    'PDF - AR Consolidated Doc',
    'PDF - BL',
    'PDF - Invoice',
    //'PDF - RE',
    'Screen Maria System',
    'FOB',
    'MOT',
    'EQ',
    'Unit',
    'GW KG-Shipment',
    'business (Negocio)',
    'Business Group',
    'Business (AOB)',
    'COO',
    'POL',
    'NCM',
    'Packing List - pdf',
    'COO/COD - pdf',
    'CRT - pdf',
    'Airwaybill - pdf',
    'Export License	 - pdf',
    'MSDS	 - pdf',
    'COA	 - pdf',
  ];

  const tableToExcel = () => {
    const targetTable = document.getElementById('reportsTable');
    const workbook = XLSX.utils.table_to_book(targetTable, {
      sheet: 'sheet1',
    });
    return XLSX.writeFileXLSX(workbook, 'report.xlsx');
  };

  useEffect(() => {
    /* FollowUpReport().then((response) => {
      setData(response);
    }); */

    Follow_Report().then((response) => {
      setData(response.data);
    });
  }, []);

  const function_ar = (row) => {
    if (row.invoice_2 && row.packing_list && row.screen_maria) {
      if (
        (row.transportation_modes == 'MARITIMO' ||
          row.transportation_modes == 'Sea') &&
        row.bill_lading
      ) {
        return 'Y';
      } else if (
        (row.transportation_modes == 'Terrestre' ||
          row.transportation_modes == 'Road') &&
        row.crt
      ) {
        return 'Y';
      } else {
        return 'N';
      }
    } else {
      return 'N';
    }
  };

  const columns = [
    {
      name: 'Sale Order',
      selector: (row) =>
        row.shipment_number ? `${row.shipment_number}` : ``,
      cell: (row) => (
        <div style={{width: 400}}>
          {row.shipment_number ? `${row.shipment_number}` : ''}
        </div>
      ),
    },
    {
      name: 'PO Number',
      selector: (row) => (row.po ? `${row.po}` : ''),
      cell: (row) => (row.po ? `${row.po}` : ''),
    },
    {
      name: 'SSB Ref.',
      selector: (row) =>
        row.numero_interno ? `${row.numero_interno}` : '',
      cell: (row) =>
        row.numero_interno ? `${row.numero_interno}` : '',
    },
    {
      name: 'Invoice',
      selector: (row) =>
        row.invoice_number ? `${row.invoice_number}` : '',
      cell: (row) =>
        row.invoice_number ? `${row.invoice_number}` : '',
    },
    {
      name: 'Shipper',
      selector: (row) =>
        row.ex_entity_name ? `${row.ex_entity_name}` : '',
      cell: (row) =>
        row.ex_entity_name ? `${row.ex_entity_name}` : '',
    },
    {
      name: 'Consignee',
      selector: (row) =>
        row.st_entity_name ? `${row.st_entity_name}` : '',
      cell: (row) =>
        row.st_entity_name ? `${row.st_entity_name}` : '',
    },
    {
      name: 'Product ID',
      selector: (row) => (row.code ? `${row.code}` : ''),
      cell: (row) => (row.code ? `${row.code}` : ''),
    },
    {
      name: 'Product Description',
      selector: (row) =>
        row.material_name ? `${row.material_name}` : '',
      cell: (row) => (
        <div style={{width: 1000}}>
          {row.material_name ? `${row.material_name}` : ''}
        </div>
      ),
    },
    {
      name: 'Freight BL',
      selector: (row) =>
        row.materials_freight ? `${row.materials_freight}` : '',
      cell: (row) =>
        row.materials_freight ? `${row.materials_freight}` : '',
    },
    {
      name: 'Plant Exit Date - Estimated',
      selector: (row) =>
        row.delivery_requested ? `${row.delivery_requested}` : '',
      cell: (row) =>
        row.delivery_requested ? `${row.delivery_requested}` : '',
    },
    {
      name: 'Plant Exit Date - Actual',
      selector: (row) => (row.ATD ? `${row.ATD}` : ''),
      cell: (row) => (row.ATD ? `${row.ATD}` : ''),
    },
    {
      name: 'ETD Date',
      selector: (row) => (row.ETD ? `${row.ETD}` : ''),
      cell: (row) => (row.ETD ? `${row.ETD}` : ''),
    },
    {
      name: 'ATD Date',
      selector: (row) => (row.ATD_s ? `${row.ATD_s}` : ''),
      cell: (row) => (row.ATD_s ? `${row.ATD_s}` : ''),
    },
    {
      name: 'ETA Date',
      selector: (row) => (row.ETA ? `${row.ETA}` : ''),
      cell: (row) => (row.ETA ? `${row.ETA}` : ''),
    },
    {
      name: 'ATA Date',
      selector: (row) => (row.ATA ? `${row.ATA}` : ''),
      cell: (row) => (row.ATA ? `${row.ATA}` : ''),
    },
    {
      name: 'Original ETA - Date',
      selector: (row) => (row.o_ETA ? `${row.o_ETA}` : ''),
      cell: (row) => (row.o_ETA ? `${row.o_ETA}` : ''),
    },

    {
      name: 'Order Rush',
      selector: (row) => (row.order_rush ? `Y` : 'N'),
      cell: (row) => (row.order_rush ? `Y` : 'N'),
    },

    {
      name: 'EntryNumber',
      selector: (row) => (row.per_export ? `${row.per_export}` : ''),
      cell: (row) => (row.per_export ? `${row.per_export}` : ''),
    },
    {
      name: 'Customs Transmission Date',
      selector: (row) =>
        row.permition_date ? `${row.permition_date}` : '',
      cell: (row) =>
        row.permition_date ? `${row.permition_date}` : '',
    },
    {
      name: 'Customs Clearance Date',
      selector: (row) =>
        row.custom_transmition_date
          ? `${row.custom_transmition_date}`
          : '',
      cell: (row) =>
        row.custom_transmition_date
          ? `${row.custom_transmition_date}`
          : '',
    },
    {
      name: 'Doc sent - Date',
      selector: (row) =>
        row.document_packet_sent ? `${row.document_packet_sent}` : '',
      cell: (row) =>
        row.document_packet_sent ? `${row.document_packet_sent}` : '',
    },
    /* {
      name: 'Note',
      selector: (row) => (row.ref_notes ? `${row.ref_notes}` : ''),
      cell: (row) => (row.ref_notes ? `${row.ref_notes}` : ''),
    }, */
    {
      name: 'Carrier',
      selector: (row) =>
        row.carrier_name ? `${row.carrier_name}` : '',
      cell: (row) => (row.carrier_name ? `${row.carrier_name}` : ''),
    },
    {
      name: 'Booking Number',
      selector: (row) =>
        row.booking_number ? `${row.booking_number}` : '',
      cell: (row) =>
        row.booking_number ? `${row.booking_number}` : '',
    },
    {
      name: 'Booking Confirmation Date',
      selector: (row) =>
        row.booking_date_confirmation
          ? `${row.booking_date_confirmation}`
          : '',
      cell: (row) =>
        row.booking_date_confirmation
          ? `${row.booking_date_confirmation}`
          : '',
    },
    {
      name: 'Destination',
      selector: (row) =>
        row.destination ? `${row.destination}` : '',
      cell: (row) => (row.destination ? `${row.destination}` : ''),
    },
    {
      name: 'Country of destinations',
      selector: (row) =>
        row.st_entity_country_name
          ? `${row.st_entity_country_name}`
          : '',
      cell: (row) =>
        row.st_entity_country_name
          ? `${row.st_entity_country_name}`
          : '',
    },
    {
      name: 'Modal',
      selector: (row) =>
        row.transportation_modes ? `${row.transportation_modes}` : '',
      cell: (row) =>
        row.transportation_modes ? `${row.transportation_modes}` : '',
    },
    {
      name: 'Type of Cargo',
      selector: (row) =>
        row.equipment_type ? `${row.equipment_type}` : '',
      cell: (row) =>
        row.equipment_type ? `${row.equipment_type}` : '',
    },
    {
      name: 'Netwight KG-Shipment',
      selector: (row) =>
        row.pricing_information_monetary_amount
          ? `${row.pricing_information_monetary_amount}`
          : '',
      cell: (row) =>
        row.pricing_information_monetary_amount
          ? `${row.pricing_information_monetary_amount}`
          : '',
    },
    {
      name: 'Register Date',
      selector: (row) =>
        row.register_date ? `${row.register_date}` : '',
      cell: (row) =>
        row.register_date ? `${row.register_date}` : '',
    },
    {
      name: 'Plant ID', //ID OR NAME?
      selector: (row) => (row.plant_name ? `${row.plant_name}` : ''),
      cell: (row) => (row.plant_name ? `${row.plant_name}` : ''),
    },
    {
      name: 'PDF - AR Consolidated Doc',
      selector: (row) => {
        if (row.invoice_2 && row.packing_list && row.screen_maria) {
          if (
            (row.transportation_modes == 'MARITIMO' ||
              row.transportation_modes == 'Sea') &&
            row.bill_lading
          ) {
            return 'Y';
          } else if (
            (row.transportation_modes == 'Terrestre' ||
              row.transportation_modes == 'Road') &&
            row.crt
          ) {
            return 'Y';
          } else {
            return 'N';
          }
        } else {
          return 'N';
        }
      },
      cell: (row) => {
        if (row.invoice_2 && row.packing_list && row.screen_maria) {
          if (
            (row.transportation_modes == 'MARITIMO' ||
              row.transportation_modes == 'Sea') &&
            row.bill_lading
          ) {
            return 'Y';
          } else if (
            (row.transportation_modes == 'Terrestre' ||
              row.transportation_modes == 'Road') &&
            row.crt
          ) {
            return 'Y';
          } else {
            return 'N';
          }
        } else {
          return 'N';
        }
      },
    },
    {
      name: 'PDF - BL',
      selector: (row) => (row.bill_lading ? `Y` : 'N'),
      cell: (row) => (row.bill_lading ? `Y` : 'N'),
    },
    {
      name: 'PDF - Invoice',
      selector: (row) => (row.invoice_2 ? `Y` : 'N'),
      cell: (row) => (row.invoice_2 ? `Y` : 'N'),
    } /* 
    {
      name: 'PDF - RE',
      selector: (row) => (row.re ? `${row.re}` : ''),
      cell: (row) => (row.re ? `${row.re}` : ''),
    }, */,
    {
      name: 'Screen Maria System',
      selector: (row) => (row.screen_maria ? `Y` : 'N'),
      cell: (row) => (row.screen_maria ? `Y` : 'N'),
    },
    {
      // ask for help
      name: 'FOB',
      selector: (row) => (row.fob ? `${parseFloat(row.fob)}` : ''),
      cell: (row) => (row.fob ? `${parseFloat(row.fob)}` : ''),
    },
    {
      name: 'MOT',
      selector: (row) => (row.mot_2 ? `${row.mot_2}` : ''),
      cell: (row) => (row.mot_2 ? `${row.mot_2}` : ''),
    },
    {
      name: 'EQ',
      selector: (row) =>
        row.containers_quantity ? `${row.containers_quantity}` : '',
      cell: (row) =>
        row.containers_quantity ? `${row.containers_quantity}` : '',
    },
    {
      name: 'Unit',
      selector: (row) => (row.quantity_m ? `${row.quantity_m}` : ''),
      cell: (row) => (row.quantity_m ? `${row.quantity_m}` : ''),
    },
    {
      name: 'GW KG-Shipment',
      selector: (row) =>
        row.totas_gross ? `${row.totas_gross}` : '',
      cell: (row) => (row.totas_gross ? `${row.totas_gross}` : ''),
    },
    {
      name: 'Business (Negocio)',
      selector: (row) => (row.bussines_g ? `${row.bussines_g}` : ''),
      cell: (row) => (row.bussines_g ? `${row.bussines_g}` : ''),
    },
    {
      name: 'Business Group',
      selector: (row) => (row.bussines_n ? `${row.bussines_n}` : ''),
      cell: (row) => (row.bussines_n ? `${row.bussines_n}` : ''),
    },
    {
      // campo que falta
      name: 'Business (AOB)',
      selector: (row) =>
        row.business_name ? `${row.business_name}` : '',
      cell: (row) =>
        row.business_name ? `${row.business_name}` : '',
    },
    {
      name: 'COO',
      selector: (row) => (row.coo_number ? `${row.coo_number}` : ''),
      cell: (row) => (row.coo_number ? `${row.coo_number}` : ''),
    },
    {
      name: 'POL',
      selector: (row) => (row.departured ? `${row.departured}` : ''),
      cell: (row) => (row.departured ? `${row.departured}` : ''),
    },
    {
      name: 'NCM',
      selector: (row) => (row.ncm ? `${row.ncm}` : ''),
      cell: (row) => (row.ncm ? `${row.ncm}` : ''),
    },
    {
      name: 'Packing List - pdf',
      selector: (row) => (row.packing_list ? `Y` : 'N'),
      cell: (row) => (row.packing_list ? `Y` : 'N'),
    },
    {
      name: 'COO/COD - pdf',
      selector: (row) => (row.coo ? `Y` : 'N'),
      cell: (row) => (row.coo ? `Y` : 'N'),
    },
    {
      name: 'CRT - pdf',
      selector: (row) => (row.crt ? `Y` : 'N'),
      cell: (row) => (row.crt ? `Y` : 'N'),
    },
    {
      name: 'Airwaybill - pdf',
      selector: (row) => (row.airwaybill ? `Y` : 'N'),
      cell: (row) => (row.airwaybill ? `Y` : 'N'),
    },
    {
      name: 'Export License	 - pdf',
      selector: (row) => (row.export_licence ? `Y` : 'N'),
      cell: (row) => (row.export_licence ? `Y` : 'N'),
    },
    {
      name: 'MSDS	 - pdf',
      selector: (row) => (row.msds ? `Y` : 'N'),
      cell: (row) => (row.msds ? `Y` : 'N'),
    },
    {
      name: 'COA	 - pdf',
      selector: (row) => (row.coa ? `Y` : 'N'),
      cell: (row) => (row.coa ? `Y` : 'N'),
    },
  ];

  const tableData = {
    columns,
    data,
  };

  return (
    <React.Fragment>
      <Head title="Exports - SSB SYSTEM"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page> FollowUp - Report</BlockTitle>
              <BlockDes className="text_soft">
                <p>export SSB System</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button
                color="light"
                outline
                className="bg-white d-none d-sm-inline-flex"
                onClick={tableToExcel}
              >
                <Icon
                  className="text-ssbnaranja"
                  name="reply-all-fill"
                ></Icon>
                <span className="text-ssbnaranja">
                  Export Container Report
                </span>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block className="rounded-pill">
          <div className="card-stretch">
            <div className="card-inner border-primary">
              {data.length > 0 ? (
                <DataTableExtensions
                  export={false}
                  print={false}
                  responsive={true}
                  {...tableData}
                >
                  <DataTable pagination striped highlightOnHover />
                </DataTableExtensions>
              ) : (
                <div className="text-center">
                  <Spinner size="lg" color="ssbnaranja" />
                </div>
              )}
            </div>
          </div>
        </Block>
        <table className="sr-only" id="reportsTable" size="sm">
          <thead>
            <tr>
              {label?.map((option) => (
                <th scope="col" key={nanoid()}>
                  {option}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((row) => (
              <tr className="tb-odr-item" key={nanoid()}>
                <td>
                  {row.shipment_number
                    ? `${row.shipment_number}`
                    : ``}
                </td>
                <td>{row.po ? `${row.po}` : ''}</td>
                <td>
                  {row.numero_interno ? `${row.numero_interno}` : ''}
                </td>
                <td>
                  {row.invoice_number ? `${row.invoice_number}` : ''}
                </td>
                <td>
                  {row.ex_entity_name ? `${row.ex_entity_name}` : ''}
                </td>
                <td>
                  {row.st_entity_name ? `${row.st_entity_name}` : ''}
                </td>
                <td>{row.code ? `${row.code}` : ''}</td>
                <td>
                  {row.material_name ? `${row.material_name}` : ''}
                </td>
                <td>
                  {row.materials_freight
                    ? `${row.materials_freight}`
                    : ''}
                </td>
                <td>
                  {row.delivery_requested
                    ? `${row.delivery_requested}`
                    : ''}
                </td>
                <td>{row.ATD ? `${row.ATD}` : ''}</td>
                <td>{row.ETD ? `${row.ETD}` : ''}</td>
                <td>{row.ATD_s ? `${row.ATD_s}` : ''}</td>
                <td>{row.ETA ? `${row.ETA}` : ''}</td>
                <td>{row.ATA ? `${row.ATA}` : ''}</td>
                <td>{row.ETA ? `${row.o_ETA}` : ''}</td>
                <td>{row.order_rush ? `Y` : 'N'}</td>
                <td>{row.per_export ? `${row.per_export}` : ''}</td>
                <td>
                  {row.permition_date ? `${row.permition_date}` : ''}
                </td>
                <td>
                  {row.custom_transmition_date
                    ? `${row.custom_transmition_date}`
                    : ''}
                </td>
                <td>
                  {row.document_packet_sent
                    ? `${row.document_packet_sent}`
                    : ''}
                </td>
                <td>
                  {row.carrier_name ? `${row.carrier_name}` : ''}
                </td>
                <td>
                  {row.booking_number ? `${row.booking_number}` : ''}
                </td>
                <td>
                  {row.booking_date_confirmation
                    ? `${row.booking_date_confirmation}`
                    : ''}
                </td>
                <td>{row.destination ? `${row.destination}` : ''}</td>
                <td>
                  {row.st_entity_country_name
                    ? `${row.st_entity_country_name}`
                    : ''}
                </td>
                <td>
                  {row.transportation_modes
                    ? `${row.transportation_modes}`
                    : ''}
                </td>
                <td>
                  {row.equipment_type ? `${row.equipment_type}` : ''}
                </td>
                <td>
                  {row.pricing_information_monetary_amount
                    ? `${row.pricing_information_monetary_amount}`
                    : ''}
                </td>
                <td>
                  {row.register_date ? `${row.register_date}` : ''}
                </td>
                <td>{row.plant_name ? `${row.plant_name}` : ''}</td>
                <td>{function_ar(row)}</td>
                <td>{row.bill_lading ? `Y` : 'N'}</td>
                <td>{row.invoice_2 ? `Y` : 'N'}</td>

                <td>{row.screen_maria ? `Y` : 'N'}</td>

                <td>{row.fob ? `${parseFloat(row.fob)}` : ''}</td>

                <td>{row.mot_2 ? `${row.mot_2}` : ''}</td>
                <td>
                  {row.containers_quantity
                    ? `${row.containers_quantity}`
                    : ''}
                </td>

                <td>{row.quantity_m ? `${row.quantity_m}` : ''}</td>

                <td>{row.totas_gross ? `${row.totas_gross}` : ''}</td>

                <td>{row.bussines_g ? `${row.bussines_g}` : ''}</td>
                <td>{row.bussines_n ? `${row.bussines_n}` : ''}</td>

                <td>
                  {row.business_name ? `${row.business_name}` : ''}
                </td>
                <td>{row.coo_number ? `${row.coo_number}` : ''}</td>

                <td>{row.departured ? `${row.departured}` : ''}</td>

                <td>{row.ncm ? `${row.ncm}` : ''}</td>

                <td>{row.packing_list ? `Y` : 'N'}</td>

                <td>{row.coo ? `Y` : 'N'}</td>
                <td>{row.crt ? `Y` : 'N'}</td>
                <td>{row.airwaybill ? `Y` : 'N'}</td>
                <td>{row.export_licence ? `Y` : 'N'}</td>
                <td>{row.msds ? `Y` : 'N'}</td>
                <td>{row.coa ? `Y` : 'N'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Content>
    </React.Fragment>
  );
};

export default FollowupReport;
