import {apiKey} from './keys.helper';
import {BACKEND} from '../../../../consts/backend';

const uploadFiles = async (file, skus, date) => {
  let info = {};
  info.file_name = file.name.replace(/  +/g, ' ');
  info.file_name = info.file_name.replaceAll(' ', '_');
  info.extension = file.name.split('.').pop();
  info.file_indentifier = file.name.split('.').shift().slice(-2);
  info.purchase_order = skus;
  info.upload_data = date;
  let requestOptions = {
    method: 'POST',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: apiKey,
    },
    body: JSON.stringify(info),
  };
  const resp = await fetch(
    `${BACKEND}/expo/add-documents`,
    requestOptions
  );

  const sku = await resp.json();
  return sku.data;
};

export default uploadFiles;
