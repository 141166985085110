/* eslint-disable no-return-await */
import HttpClient from './providers.helper';

import {BACKEND} from '../../../../consts/backend';

class FileProvider {
  httpClient = HttpClient;

  async submitFiles(file, orderNumber) {
    const formData = new FormData();
    formData.append('file', file);
    return await this.httpClient.post(
      `${BACKEND}/order/${orderNumber}/file`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }

  async SubirExcel(file) {
    const formData = new FormData();
    formData.append('file', file);
    return await this.httpClient.post(
      `${BACKEND}/excel/file`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          origin: 'x-requested-with',
          'Access-Control-Allow-Headers':
            'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin',
          'Access-Control-Allow-Origin': '*',
        },
      }
    );
  }

  async getFiles(orderNumber) {
    return await this.httpClient.get(
      `${BACKEND}/order/${orderNumber}/files`
    );
  }

  async getAssetsFiles() {
    return await this.httpClient.get(`${BACKEND}/help-assets/files`);
  }

  async getFilesBD(orderNumber) {
    return await this.httpClient.get(
      `${BACKEND}/documents/get-files/${orderNumber}`
    );
  }

  async uploadFiles(file, orderNumber, data) {
    const body = {
      file,
      po: orderNumber,
      date: data,
    };
    return await this.httpClient.post(
      `${BACKEND}/expo/add-documents`,
      body
    );
  }

  async getFilesLast(orderNumber) {
    return await this.httpClient.get(
      `${BACKEND}/documents/get-files/${orderNumber}`
    );
  }
}
export default FileProvider;
