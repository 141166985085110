import {apiKey} from '../../followUp/Components/helpers/keys.helper';
import {BACKEND} from '../../../consts/backend.js';

const excelIntegration = async (data) => {
  let requestOptions = {
    method: 'PATCH',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: apiKey,
    },
    body: JSON.stringify(data),
  };

  const resp = await fetch(
    `${BACKEND}/expo/excel-integration`,
    requestOptions
  );

  const sku = await resp.json();
  return sku;
};

export default excelIntegration;
