import {BACKEND} from '../../../../consts/backend';

export const FollowUpReport = async (id) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
  };

  const resp = await fetch(
    `${BACKEND}/expo/report_followup`,
    requestOptions
  );
  const sku = await resp.json();
  return sku.data;
};

export const Get_totals_order = async() => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
  };

  const resp = await fetch(`${BACKEND}/expo/definitive_followup/count`, requestOptions);
  const sku = await resp.json();

  return sku;
}

export const Follow_Report = async (index= 0) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  };

  let url = `${BACKEND}/expo/definitive_followup`
  let total = []
  let orders = {
    error: null,
    data: []
  }
  do{
    const resp = await fetch(url+ `?lastIndex=${index}`, requestOptions);
    orders = await resp.json();
    total = [...total, ...orders.data]
    index += orders.data.length
  }while (orders.data.length === 500)

  orders.data = total

  return orders
}