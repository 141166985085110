import React, {Suspense, useLayoutEffect, lazy} from 'react';
import {Switch, Route} from 'react-router-dom';
import {ProductContextProvider} from '../pages/pre-built/products/ProductContext';
import {UserContextProvider} from '../../src/listgsa/components/UserContext';
import {RedirectAs404} from '../utils/Utils';
import Sales from '../pages/Sales';
import Crypto from '../pages/Crypto';
import Analytics from '../pages/Analytics';
import ViewExpoCustomReport from '../Exportation/Reports/custom-report/view-custom-report';

import Muestras from '../muestras';


import FollowupReport from '../Exportation/followUp/Components/followupExpoReport';
import {UserManagement} from '../userManagement';
import {MacroUpdate} from '../Exportation/macroUpdate';
import {NewOrder} from '../Exportation/followUp/Components/NewOrder/NewOrder';
import Helperdocuments from '../Exportation/followUp/Components/helperdocuments';

//import Invest from "../pages/Invest";
const ExpoReportList = lazy(() =>
  import('../Exportation/Reports/custom-report/report-list')
);
const ReportsExpo = lazy(() =>
  import('../Exportation/Reports/custom-report/custom-report')
);
const Component = lazy(() => import('../pages/components/Index'));
const Accordian = lazy(() =>
  import('../pages/components/Accordions')
);
const Alerts = lazy(() => import('../pages/components/Alerts'));
const Badges = lazy(() => import('../pages/components/Badges'));
const Breadcrumbs = lazy(() =>
  import('../pages/components/Breadcrumbs')
);
const ButtonGroup = lazy(() =>
  import('../pages/components/ButtonGroup')
);
const Buttons = lazy(() => import('../pages/components/Buttons'));
const Cards = lazy(() => import('../pages/components/Cards'));
const Carousel = lazy(() => import('../pages/components/Carousel'));
const Dropdowns = lazy(() => import('../pages/components/Dropdowns'));
const FormElements = lazy(() =>
  import('../pages/components/forms/FormElements')
);
const FormLayouts = lazy(() =>
  import('../pages/components/forms/FormLayouts')
);
const FormValidation = lazy(() =>
  import('../pages/components/forms/FormValidation')
);
const Modals = lazy(() => import('../pages/components/Modals'));
const Pagination = lazy(() =>
  import('../pages/components/Pagination')
);
const Popovers = lazy(() => import('../pages/components/Popovers'));
const Progress = lazy(() => import('../pages/components/Progress'));
const Spinner = lazy(() => import('../pages/components/Spinner'));
const Tabs = lazy(() => import('../pages/components/Tabs'));
const Toast = lazy(() => import('../pages/components/Toast'));
const Tooltips = lazy(() => import('../pages/components/Tooltips'));
const UtilBorder = lazy(() =>
  import('../pages/components/UtilBorder')
);
const UtilColors = lazy(() =>
  import('../pages/components/UtilColors')
);
const UtilDisplay = lazy(() =>
  import('../pages/components/UtilDisplay')
);
const UtilEmbeded = lazy(() =>
  import('../pages/components/UtilEmbeded')
);
const UtilFlex = lazy(() => import('../pages/components/UtilFlex'));
const UtilOthers = lazy(() =>
  import('../pages/components/UtilOthers')
);
const UtilSizing = lazy(() =>
  import('../pages/components/UtilSizing')
);
const UtilSpacing = lazy(() =>
  import('../pages/components/UtilSpacing')
);
const UtilText = lazy(() => import('../pages/components/UtilText'));
const Blank = lazy(() => import('../pages/others/Blank'));
const Faq = lazy(() => import('../pages/others/Faq'));
const Regularv1 = lazy(() => import('../pages/others/Regular-1'));
const Regularv2 = lazy(() => import('../pages/others/Regular-2'));
const Terms = lazy(() => import('../pages/others/Terms'));
const BasicTable = lazy(() =>
  import('../pages/components/table/BasicTable')
);
const SpecialTablePage = lazy(() =>
  import('../pages/components/table/SpecialTable')
);
const ChartPage = lazy(() =>
  import('../pages/components/charts/Charts')
);
const EmailTemplate = lazy(() =>
  import('../pages/components/email-template/Email')
);
const NioIconPage = lazy(() =>
  import('../pages/components/crafted-icons/NioIcon')
);
const SVGIconPage = lazy(() =>
  import('../pages/components/crafted-icons/SvgIcons')
);
const ProjectCardPage = lazy(() =>
  import('../pages/pre-built/projects/ProjectCard')
);
const ProjectListPage = lazy(() =>
  import('../pages/pre-built/projects/ProjectList')
);
const UserListRegularPage = lazy(() =>
  import('../pages/pre-built/user-manage/UserListRegular')
);
const UserContactCardPage = lazy(() =>
  import('../pages/pre-built/user-manage/UserContactCard')
);
const UserDetailsPage = lazy(() =>
  import('../pages/pre-built/user-manage/UserDetailsRegular')
);
const UserListCompact = lazy(() =>
  import('../pages/pre-built/user-manage/UserListCompact')
);
const UserProfileLayout = lazy(() =>
  import('../pages/pre-built/user-manage/UserProfileLayout')
);
const KycListRegular = lazy(() =>
  import('../pages/pre-built/kyc-list-regular/KycListRegular')
);
const KycDetailsRegular = lazy(() =>
  import('../pages/pre-built/kyc-list-regular/kycDetailsRegular')
);
const TransListBasic = lazy(() =>
  import('../pages/pre-built/trans-list/TransListBasic')
);
const TransListCrypto = lazy(() =>
  import('../pages/pre-built/trans-list/TransListCrypto')
);
const ProductCard = lazy(() =>
  import('../pages/pre-built/products/ProductCard')
);
const ProductList = lazy(() =>
  import('../pages/pre-built/products/ProductList')
);
const ProductDetails = lazy(() =>
  import('../pages/pre-built/products/ProductDetails')
);
const InvoiceList = lazy(() =>
  import('../pages/pre-built/invoice/InvoiceList')
);
const InvoiceDetails = lazy(() =>
  import('../pages/pre-built/invoice/InvoiceDetails')
);
const PricingTable = lazy(() =>
  import('../pages/pre-built/pricing-table/PricingTable')
);
const Gallery = lazy(() =>
  import('../pages/pre-built/gallery/GalleryCard')
);
const AppMessages = lazy(() =>
  import('../pages/app/messages/Messages')
);
const Chat = lazy(() => import('../pages/app/chat/ChatContainer'));
const Calender = lazy(() => import('../pages/app/calender/Calender'));
const DateTimePicker = lazy(() =>
  import('../pages/components/forms/DateTimePicker')
);

//Importacion de Lista de Seller
const ListGsa = lazy(() => import('../listgsa'));
const SellerDetailsPage = lazy(() =>
  import('../listgsa/components/SellerDetails')
);
const DetallesdelSeller = lazy(() =>
  import('../listgsa/components/detallesdelseller')
);
const ListPlasticos = lazy(() => import('../plasticos'));
const DetailOrder = lazy(() =>
  import('../plasticos/components/detailorder')
);
const Followup = lazy(() =>
  import('../plasticos/components/followupreport')
);
const ContainerReport = lazy(() =>
  import('../Exportation/followUp/Components/followupcontainerreport')
);
const Calculadora = lazy(() => import('../tarifas'));
const PowerBi = lazy(() => import('../powerbi'));
const Importaciones = lazy(() => import('../dashimpo'));
const ReportImpo = lazy(() =>
  import('../plasticos/components/Reports/reportImpo')
);
const Arrivals = lazy(() =>
  import('../dashimpo/components/arrivals')
);
const PlasticOrigin = lazy(() =>
  import('../dashimpo/components/plasticorigin')
);
const ReportsExportation = lazy(() =>
  import('../Exportation/expoReport/reportsExportation')
);
const ExpoDashboard = lazy(() =>
  import('../Exportation/expoDashboard/expodashboard')
);
const FollowUpExportacion = lazy(() =>
  import('../Exportation/followUp/followUpExportacion')
);
const Reports = lazy(() =>
  import(
    '../plasticos/components/Reports/custom-report/custom-report'
  )
);
const ReportList = lazy(() =>
  import('../plasticos/components/Reports/custom-report/report-list')
);
const ViewCustomReport = lazy(() =>
  import(
    '../plasticos/components/Reports/custom-report/view-custom-report'
  )
);
const ExpoDetailOrder = lazy(() =>
  import('../Exportation/followUp/followUpExpoDetail')
);
const FollowUpExpoDemo = lazy(() =>
  import('../Exportation/followUp/followUpExpoDemo')
);
const ExpoDetailOrderDemo = lazy(() =>
  import('../Exportation/followUp/followUpExpoDetailDemo')
);
const Arrivals2 = lazy(() =>
  import('../dashimpo/components/arrivals2')
);

const MasterExpoDB = lazy(() =>
  import('../Exportation/followUp/master-expo-db')
);

const SubirDocumentoExcel = lazy(() =>
  import('../Exportation/followUp/Components/subirDocumeto')
);

const Pages = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Suspense fallback={<div />}>
      <Switch>
        {/*Dashboards*/}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/crypto`}
          component={Crypto}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/analytics`}
          component={Analytics}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/sales`}
          component={Sales}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/_blank`}
          component={Blank}
        ></Route>

        {/*Pre-built Pages*/}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/project-card`}
          component={ProjectCardPage}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/project-list`}
          component={ProjectListPage}
        ></Route>
        <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/user-list-regular`}
          render={() => (
            <UserContextProvider>
              <UserListRegularPage />
            </UserContextProvider>
          )}
        ></Route>
        <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/user-list-compact`}
          render={() => (
            <UserContextProvider>
              <UserListCompact />
            </UserContextProvider>
          )}
        ></Route>
        <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/user-details-regular/:id`}
          render={(props) => (
            <UserContextProvider>
              <UserDetailsPage {...props} />
            </UserContextProvider>
          )}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/user-profile-regular/`}
          component={UserProfileLayout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/user-profile-notification/`}
          component={UserProfileLayout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/user-profile-activity/`}
          component={UserProfileLayout}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/user-profile-setting/`}
          component={UserProfileLayout}
        ></Route>
        <Route //Context api added
          exact
          path={`${process.env.PUBLIC_URL}/user-contact-card`}
          render={() => (
            <UserContextProvider>
              <UserContactCardPage />
            </UserContextProvider>
          )}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/kyc-list-regular`}
          component={KycListRegular}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/kyc-details-regular/:id`}
          component={KycDetailsRegular}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/transaction-basic`}
          component={TransListBasic}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/transaction-crypto`}
          component={TransListCrypto}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/product-list`}
          component={ProductList}
        ></Route>

        <Route // context api added
          exact
          path={`${process.env.PUBLIC_URL}/product-card`}
          render={(props) => (
            <ProductContextProvider>
              <ProductCard />
            </ProductContextProvider>
          )}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/product-details/:id`}
          render={(props) => (
            <ProductContextProvider>
              <ProductDetails {...props} />
            </ProductContextProvider>
          )}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/invoice-list`}
          component={InvoiceList}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/invoice-details/:id`}
          component={InvoiceDetails}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/pricing-table`}
          component={PricingTable}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/image-gallery`}
          component={Gallery}
        ></Route>

        {/*Demo Pages*/}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/pages/terms-policy`}
          component={Terms}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/pages/faq`}
          component={Faq}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/pages/regular-v1`}
          component={Regularv1}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/pages/regular-v2`}
          component={Regularv2}
        ></Route>

        {/*Application*/}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/app-messages`}
          component={AppMessages}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/app-chat`}
          component={Chat}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/app-calender`}
          component={Calender}
        ></Route>

        {/*Components*/}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components`}
          component={Component}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/accordions`}
          component={Accordian}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/alerts`}
          component={Alerts}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/badges`}
          component={Badges}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/breadcrumbs`}
          component={Breadcrumbs}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/button-group`}
          component={ButtonGroup}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/buttons`}
          component={Buttons}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/cards`}
          component={Cards}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/carousel`}
          component={Carousel}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/dropdowns`}
          component={Dropdowns}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/form-elements`}
          component={FormElements}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/form-layouts`}
          component={FormLayouts}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/form-validation`}
          component={FormValidation}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/datetime-picker`}
          component={DateTimePicker}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/modals`}
          component={Modals}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/pagination`}
          component={Pagination}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/popovers`}
          component={Popovers}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/progress`}
          component={Progress}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/spinner`}
          component={Spinner}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/tabs`}
          component={Tabs}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/toast`}
          component={Toast}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/tooltips`}
          component={Tooltips}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/util-border`}
          component={UtilBorder}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/util-colors`}
          component={UtilColors}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/util-display`}
          component={UtilDisplay}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/util-embeded`}
          component={UtilEmbeded}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/util-flex`}
          component={UtilFlex}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/util-others`}
          component={UtilOthers}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/util-sizing`}
          component={UtilSizing}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/util-spacing`}
          component={UtilSpacing}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/components/util-text`}
          component={UtilText}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/table-basic`}
          component={BasicTable}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/table-special`}
          component={SpecialTablePage}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/charts/chartjs`}
          component={ChartPage}
        ></Route>
        <Route
          exactdetailOrderex
          path={`${process.env.PUBLIC_URL}/email-template`}
          component={EmailTemplate}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/nioicon`}
          component={NioIconPage}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/svg-icons`}
          component={SVGIconPage}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/`}
          component={Importaciones}
        ></Route>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/expo-detailOrder/:po`}
          component={ExpoDetailOrder}
        ></Route>
        {/*demo new order*/}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/newOrder`}
          component={NewOrder}
        ></Route>
        {}
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/expo-detailOrderDemo/:po`}
          component={ExpoDetailOrderDemo}
        ></Route>

        {
          //Lista de Seller
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/listgsa`}
            component={ListGsa}
          ></Route>
        }
        {
          //Lista de Seller
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/listplasticos`}
            component={ListPlasticos}
          ></Route>
        }
        {
          <Route //Context Api added
            exact
            path={`${process.env.PUBLIC_URL}/seller-details/:id`}
            render={(props) => (
              <UserContextProvider>
                <SellerDetailsPage {...props} />
              </UserContextProvider>
            )}
          ></Route>
        }
        {
          <Route //Context Api added
            exact
            path={`${process.env.PUBLIC_URL}/detalleseller/:id`}
            render={(props) => <DetallesdelSeller {...props} />}
          ></Route>
        }
        {
          <Route //Context Api added
            exact
            path={`${process.env.PUBLIC_URL}/detallesorders/:id`}
            render={(props) => <PlasticOrigin {...props} />}
          ></Route>
        }
        {
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/detailorder/:id`}
            render={(props) => <DetailOrder {...props} />}
          ></Route>
        }
        {
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/followup`}
            component={Followup}
          ></Route>
        }
        {/* Test */}
        {
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/containerExport`}
            component={ContainerReport}
          ></Route>
        }
        {
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/follow-up-report`}
            component={FollowupReport}
          ></Route>
        }
        {/* user management */}
        {
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/user_management`}
            component={UserManagement}
          ></Route>
        }
        {/*  */}
        {/* macroEdit  */}
        {
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/macroUpdate`}
            component={MacroUpdate}
          ></Route>
        }
        {/*  */}
        {
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/calculator`}
            component={Calculadora}
          ></Route>
        }
        {
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/powerbi`}
            component={PowerBi}
          ></Route>
        }
        {
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/dashimpo`}
            component={Importaciones}
          ></Route>
        }
        {
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/followUpExpo`}
            component={FollowUpExportacion}
          ></Route>
        }
        {
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/followUpExpoDemo`}
            component={FollowUpExpoDemo}
          ></Route>
        }
        {
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/reportExpo`}
            component={ReportsExportation}
          ></Route>
        }
        {
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/dashboardExpo`}
            component={ExpoDashboard}
          ></Route>
        }
        {
          <Route
            exact
            path={`/reportImpo`}
            component={ReportImpo}
          ></Route>
        }
        {
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/dragreport`}
            component={Reports}
          ></Route>
        }
        {
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/dragreport/:reportId`}
            component={Reports}
          ></Route>
        }
        {
          <Route
            exact
            path={`/reportList`}
            component={ReportList}
          ></Route>
        }
        {
          <Route
            exact
            path={`/exporeportlist`}
            component={ExpoReportList}
          ></Route>
        }
        {
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/dragreportexport`}
            component={ReportsExpo}
          ></Route>
        }
        {
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/dragreportexport/:reportId`}
            component={ReportsExpo}
          ></Route>
        }
        {
          <Route
            exact
            path={`/viewExpoCustomReport/:reportId`}
            component={ViewExpoCustomReport}
          ></Route>
        }
        {
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/arrivals`}
            component={Arrivals}
          ></Route>
        }
        {
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/arrivals2`}
            component={Arrivals2}
          ></Route>
        }
        {
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/muestras`}
            component={Muestras}
          ></Route>
        }
        {
          <Route
            exact
            path={`/viewCustomReport/:reportId`}
            component={ViewCustomReport}
          ></Route>
        }
        {
          <Route
            exact
            path={`/expo/master-db`}
            component={MasterExpoDB}
          ></Route>
        }
        {
          <Route
            exact
            path={`/subir-excel`}
            component={SubirDocumentoExcel}
          ></Route>
        }
        {
          <Route
            exact
            path={`/help-documents`}
            component={Helperdocuments}
          ></Route>
        }
        <Route component={RedirectAs404}></Route>
      </Switch>
    </Suspense>
  );
};
export default Pages;
